/* eslint-disable import/no-unresolved */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import {
    DashboardPluginV1,
    newCustomWidget,
    newDashboardItem,
    newDashboardSection,
} from '@gooddata/sdk-ui-dashboard';
import VenueFilterWidget from 'components/Dashboard/Widgets/VenueFilterWidget';

/**
 * The VenueFilterPlugin extends the DashboardPluginV1 and registers our custom widget VenueFilterWidget
 */
class VenueFilterPlugin extends DashboardPluginV1 {
    register(_ctx, customize, _handlers) {
        customize.customWidgets().addCustomWidget('venueFilterWidget', VenueFilterWidget);
        customize.layout().customizeFluidLayout((_layout, customizer) => {
            customizer.addSection(
                0,
                newDashboardSection(
                    '',
                    newDashboardItem(
                        newCustomWidget(
                            'impersonationScopeWidget',
                            'venueFilterWidget',
                        ),
                        {
                            xl: {
                                // All 12 columns of the grid will be 'allocated' for this new item
                                gridWidth: 12,
                                // Medium height to fit the chart
                                gridHeight: 3,
                            },
                        },
                    ),
                ),
            );
        });
    }
}

export default VenueFilterPlugin;
