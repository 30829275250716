import { selector } from 'recoil';
import translationsState from 'core/framework/recoil/translations/atom';

const genericTranslations = selector({
    key: 'genericTranslations',
    get: ({ get }) => {
        const translations = get(translationsState);
        return translations?.generic;
    },
});

export default genericTranslations;
