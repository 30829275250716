import React from 'react';
import { useRecoilValue } from 'recoil';
import HeaderPattern from 'components/PageHeader/SVGPageHeaderPattern.svg';
import { generic } from 'core/framework/recoil/translations';
import accountInfoAtom from 'core/framework/recoil/atoms/account-info-atom';
import TranslationHelper from 'core/utils/translation-helper';
import ImpersonationMenu from './ImpersonationMenu/ImpersonationMenu';

import './PageHeader.scss';

/**
 * The PageHeader component renders the welcome banner for the application.
 * @returns {JSX.Element} The rendered PageHeader component.
 */
const PageHeader = () => {
    const genericTranslations = useRecoilValue(generic);

    const {
        firstname = '',
        username = '',
    } = useRecoilValue(accountInfoAtom);

    const welcomeMessage = () => (
        firstname
            ? TranslationHelper.interpolateString(genericTranslations.helloUser, [firstname])
            : genericTranslations.hello
    );

    return (
        <div
            className="PageHeader"
            style={{ backgroundImage: `url(${HeaderPattern})` }}
        >
            <div className="PageHeader__text">
                <div className="greeting">
                    <h2>{welcomeMessage()}</h2>
                </div>
                <div className="profile-info">
                    <p>{username}</p>
                </div>
            </div>
            <div className="PageHeader__controls">
                <ImpersonationMenu />
            </div>
        </div>
    );
};

export default PageHeader;
