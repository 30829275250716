import React from 'react';
import PropTypes from 'prop-types';
import AccountMenu from 'core/framework/components/AccountMenu/AccountMenu';

import './Topbar.scss';

/**
 * Topbar component renders the top bar of the application.
 *
 * @returns {JSX.Element} The rendered Topbar component.
 */
const Topbar = ({ isMainWhitelabel }) => (
    <div className="Topbar">
        <div className="TopbarSection TopbarSection--left" />
        <div className="TopbarSection TopbarSection--center">
            <div className="logo">
                {isMainWhitelabel && (
                    <span className="prefix">purple</span>
                )}
                <span className="product-name">{isMainWhitelabel ? 'analytics' : 'Analytics'}</span>
                <span className="beta">BETA</span>
            </div>
        </div>
        <div className="TopbarSection TopbarSection--right">
            <AccountMenu />
        </div>
    </div>
);

Topbar.propTypes = {
    isMainWhitelabel: PropTypes.bool,
};

Topbar.defaultProps = {
    isMainWhitelabel: false,
};

export default Topbar;
