import React, { useEffect, useState } from 'react';
import { BlankFeature, Spinner } from 'metroplex';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { generic } from 'core/framework/recoil/translations';
import AuthenticationService from 'core/framework/services/authentication-service';

const OAuth = () => {
    const translations = useRecoilValue(generic);
    const [URLSearchParams] = useSearchParams();
    const [stateError, setStateError] = useState(null);

    useEffect(() => {
        if (URLSearchParams.get('state') !== AuthenticationService.getOAuthState()) {
            setStateError(true);
        } else {
            AuthenticationService.requestAccessToken(URLSearchParams.get('code'));
        }
    }, []);

    return (
        <div className="PageWrapper">
            <div className="PageContent PageContent--loading">
                {stateError ? (
                    <BlankFeature title={translations.oAuthErrorP1} icon="sadRobot">
                        <p>{translations.oAUthErrorP2}</p>
                    </BlankFeature>
                ) : (
                    <Spinner page loadingText={translations.authenticating} />
                )}
            </div>
        </div>
    );
};

export default OAuth;
