import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { showSpinner } from 'core/framework/recoil/page';
import Topbar from 'core/framework/components/Topbar/Topbar';
import TopbarStyling from 'core/framework/components/Topbar/TopbarStyling';
import brandingAtom from 'core/framework/recoil/atoms/branding-atom';

import './Frame.scss';

/**
 * Frame component serves as the main layout container for the application.
 * It includes the top bar, primary color styling, and a spinner for loading states.
 *
 * @param {React.ReactNode} props.children - The child components to be rendered within the Frame.
 * @param {bool} props.renderTopbar - If the Topbar should be rendered.
 *
 * @returns {JSX.Element} The rendered Frame component.
 */
const Frame = ({ children, renderTopbar }) => {
    const showPageSpinner = useRecoilValue(showSpinner);
    const { navigationBackgroundColour, isMainWhitelabel } = useRecoilValue(brandingAtom);
    const isIFrame = window.location.pathname.includes('/iframe');

    return (
        <div className={`Frame ${isIFrame && 'Frame--iframe'}`}>
            {renderTopbar && (
                <Fragment>
                    <TopbarStyling primaryColour={navigationBackgroundColour} />
                    <Topbar isMainWhitelabel={isMainWhitelabel} />
                    {showPageSpinner && (
                        <div className="PageSpinner">
                            <svg viewBox="0 0 26 26">
                                <circle cx="4" cy="13" r="3" fillOpacity="0.8" />
                                <circle cx="13" cy="13" r="3" />
                                <circle cx="22" cy="13" r="3" fillOpacity="0.8" />
                            </svg>
                        </div>
                    )}
                </Fragment>
            )}
            {children}
        </div>
    );
};

Frame.propTypes = {
    children: PropTypes.node,
    renderTopbar: PropTypes.bool,
};

Frame.defaultProps = {
    children: null,
    renderTopbar: true,
};

export default Frame;
