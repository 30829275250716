import Request from 'core/utils/request';

const BrandingService = {
    getBrandingForDomain: (successCallback, errorCallback) => {
        const domain = window.location.hostname;
        const brandingBaseUrl = `${window.location.origin}/services/branding`;
        const brandingUrl = `${brandingBaseUrl}/whitelabel/${domain}`;

        Request.get(brandingUrl)
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },
};

export default BrandingService;
