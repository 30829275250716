/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { ICONS } from 'metroplex';

export const IconComponents = {
    Dashboards: () => (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8.80981" y="0.631622" width="3.19018" height="13.3684" rx="1.59509" fill="#4575DC" />
            <rect x="4.6322" y="5.72072" width="3.19018" height="8.27927" rx="1.59509" fill="#4575DC" />
            <rect x="0.45459" y="10.8098" width="3.19018" height="3.19018" rx="1.59509" fill="#4575DC" />
        </svg>
    ),
    Help: () => (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.685 13.685C2.99287 13.685 0 10.6921 0 7C0 3.30787 2.99287 0.314999 6.685 0.314999C10.3771 0.314999 13.37 3.30787 13.37 7C13.37 10.6921 10.3771 13.685 6.685 13.685ZM3.95752 5.15093V5.21911C3.95752 5.30776 3.99274 5.39278 4.05542 5.45546C4.1181 5.51815 4.20312 5.55336 4.29177 5.55336H4.95826C5.00207 5.55336 5.04545 5.54474 5.08592 5.52797C5.12639 5.51121 5.16317 5.48664 5.19414 5.45566C5.22512 5.42469 5.24969 5.38791 5.26645 5.34744C5.28322 5.30697 5.29185 5.26359 5.29185 5.21978C5.29185 4.35742 5.95901 3.89682 6.85614 3.89682C7.73053 3.89682 8.30544 4.35742 8.30544 5.01322C8.30544 5.63358 7.98323 5.92171 7.17835 6.29005L6.94839 6.393C6.26986 6.69249 6.0165 7.14038 6.0165 7.9232V8.00275C6.0165 8.0914 6.05172 8.17642 6.1144 8.2391C6.17708 8.30178 6.2621 8.337 6.35075 8.337H7.01724C7.06105 8.337 7.10443 8.32837 7.1449 8.31161C7.18537 8.29484 7.22215 8.27027 7.25312 8.2393C7.2841 8.20832 7.30867 8.17155 7.32543 8.13107C7.3422 8.0906 7.35083 8.04722 7.35083 8.00342C7.35083 7.65847 7.44308 7.52009 7.7185 7.39374L7.94913 7.29013C8.91512 6.85293 9.63977 6.27802 9.63977 5.02391V4.95506C9.63977 3.63276 8.48995 2.65475 6.85614 2.65475C5.19959 2.65475 3.95752 3.60937 3.95752 5.15093ZM5.68225 10.3365C5.68225 10.9161 6.12279 11.3452 6.67898 11.3452C7.24721 11.3452 7.68775 10.9161 7.68775 10.3365C7.68775 9.75689 7.24721 9.33975 6.67898 9.33975C6.12279 9.33975 5.68225 9.75689 5.68225 10.3365Z" fill="#4575DC" />
        </svg>
    ),
    ExternalLink: () => (
        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M7.99977 1.44318L0.884018 8.55684" stroke="#616374" strokeWidth="1.58713" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.00001 8.39262V1.44318H1.05057" stroke="#616374" strokeWidth="1.58713" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    ),
};

export default {
    ...ICONS,
    dropdownCaret: {
        title: 'dropdown caret',
        viewBox: '0 0 10 6',
        path: 'M5.70715 5.29289C5.31663 5.68342 4.68346 5.68342 4.29294 5.29289L0.707153 1.70711C0.0771886 1.07714 0.523355 0 1.41426 0H8.58583C9.47674 0 9.9229 1.07714 9.29294 1.70711L5.70715 5.29289Z',
    },
    helpTopBar: {
        title: 'Support guides',
        viewBox: '0 0 14 14',
        path: 'M7 14C3.134 14 0 10.866 0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7C14 10.866 10.866 14 7 14ZM7 12.6C10.0928 12.6 12.6 10.0928 12.6 7C12.6 3.9072 10.0928 1.4 7 1.4C3.9072 1.4 1.4 3.9072 1.4 7C1.4 10.0928 3.9072 12.6 7 12.6ZM6.3 9.1H7.7V10.5H6.3V9.1ZM7.7 7.94857V8.4H6.3V7.35C6.3 6.96339 6.61339 6.65 7 6.65C7.57988 6.65 8.05 6.17988 8.05 5.6C8.05 5.0201 7.57988 4.55 7 4.55C6.49061 4.55 6.06592 4.91276 5.97016 5.39404L4.59712 5.11942C4.82046 3.99643 5.8114 3.15 7 3.15C8.3531 3.15 9.45 4.2469 9.45 5.6C9.45 6.70985 8.71199 7.64736 7.7 7.94857Z',
    },
    signOut: {
        title: 'Sign out',
        viewBox: '0 0 14 14',
        path: 'M0.7 14C0.313404 14 0 13.6866 0 13.3V0.7C0 0.313404 0.313404 0 0.7 0H10.5C10.8866 0 11.2 0.313404 11.2 0.7V2.1C11.2 2.4866 10.8866 2.8 10.5 2.8V2.8C10.1134 2.8 9.8 2.4866 9.8 2.1V1.4H1.4V12.6H9.8V11.9C9.8 11.5134 10.1134 11.2 10.5 11.2V11.2C10.8866 11.2 11.2 11.5134 11.2 11.9V13.3C11.2 13.6866 10.8866 14 10.5 14H0.7ZM10.6123 9.15012C10.285 9.41203 9.8 9.17894 9.8 8.75969V7.7H5.6C5.2134 7.7 4.9 7.3866 4.9 7V7C4.9 6.6134 5.2134 6.3 5.6 6.3H9.8V5.24031C9.8 4.82106 10.285 4.58797 10.6123 4.84988L12.812 6.60957C13.0622 6.80973 13.0622 7.19027 12.812 7.39043L10.6123 9.15012Z',
    },
    impersonationEye: {
        title: 'Impersonation Eye',
        viewBox: '-1.5 -2 20 20',
        path: 'M8.62361 2C6.37936 2 4.5823 2.98571 3.27408 4.15893C1.97419 5.32143 1.10482 6.71429 0.693744 7.67054C0.602085 7.88214 0.602085 8.11786 0.693744 8.32946C1.10482 9.28571 1.97419 10.6786 3.27408 11.8411C4.5823 13.0143 6.37936 14 8.62361 14C10.8679 14 12.6649 13.0143 13.9731 11.8411C15.273 10.6759 16.1424 9.28571 16.5563 8.32946C16.6479 8.11786 16.6479 7.88214 16.5563 7.67054C16.1424 6.71429 15.273 5.32143 13.9731 4.15893C12.6649 2.98571 10.8679 2 8.62361 2ZM4.62396 8C4.62396 6.97702 5.04535 5.99594 5.79543 5.27259C6.54551 4.54923 7.56284 4.14286 8.62361 4.14286C9.68439 4.14286 10.7017 4.54923 11.4518 5.27259C12.2019 5.99594 12.6233 6.97702 12.6233 8C12.6233 9.02298 12.2019 10.0041 11.4518 10.7274C10.7017 11.4508 9.68439 11.8571 8.62361 11.8571C7.56284 11.8571 6.54551 11.4508 5.79543 10.7274C5.04535 10.0041 4.62396 9.02298 4.62396 8ZM8.62361 6.28571C8.62361 7.23125 7.82646 8 6.84599 8C6.64878 8 6.45991 7.96786 6.28215 7.91161C6.12938 7.86339 5.95162 7.95446 5.95718 8.10982C5.96551 8.29464 5.99328 8.47946 6.04606 8.66429C6.42658 10.0357 7.89034 10.85 9.31244 10.483C10.7345 10.1161 11.5789 8.70446 11.1984 7.33304C10.8901 6.22143 9.87073 5.47411 8.73749 5.42857C8.57639 5.42321 8.48196 5.59196 8.53195 5.74196C8.59028 5.91339 8.62361 6.09554 8.62361 6.28571Z',
    },
};
