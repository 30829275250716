import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Spinner } from 'metroplex';
import { find, propEq } from 'ramda';
import { useNavigate, useParams } from 'react-router';
import { IconComponents } from 'core/icons';
import selectedDashboardAtom from 'core/framework/recoil/atoms/selected-dashboard-atom';
import dashboardAtom from 'core/framework/recoil/atoms/dashboard-atom';
import AnalyticsService from 'core/framework/services/analytics-service';
import ENV from 'core/utils/env-helper';
import { alert } from 'core/framework/recoil/page';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';

import 'components/Sidebar/Sidebar.scss';

const R = { find, propEq };

/**
 * Sidebar component renders the navigation menu for the application.
 *
 * @returns {JSX.Element} The rendered Sidebar component.
 */
const Sidebar = () => {
    const impersonation = useRecoilValue(impersonationAtom);
    const [dashboards, setDashboards] = useRecoilState(dashboardAtom);
    const [selectedDashboard, setSelectedDashboard] = useRecoilState(selectedDashboardAtom);
    const setAlert = useSetRecoilState(alert);
    const navigate = useNavigate();
    const urlParams = useParams();

    useEffect(() => {
        AnalyticsService.getDashboards(
            impersonation.userScope,
            response => {
                const currentDashboard = urlParams.dashboardId ?? ENV.get().REACT_APP_DEFAULT_DASHBOARD_ID;
                const dashboard = R.find(R.propEq('id', currentDashboard), response);

                setDashboards(response);

                if (!dashboard) {
                    return;
                }

                setSelectedDashboard(dashboard);
            },
            error => setAlert({ show: true, message: error.message, type: 'error' }),
        );
    }, [impersonation.userScope]);

    const updateDashboard = dashboardId => {
        const dashboard = R.find(R.propEq('id', dashboardId), dashboards);

        if (!dashboard) {
            return;
        }

        setSelectedDashboard(dashboard);

        navigate(`/dashboard/${dashboardId}${window.location.search}`);
    };

    return (
        <div className="left-menu">
            <div className="section-title">
                <div className="section-title__icon">
                    {IconComponents.Dashboards()}
                </div>
                <h1>Dashboards</h1>
            </div>
            {dashboards.length > 0
                ? (
                    <div className="links">
                        <ul>
                            {dashboards.map(
                                dashboard => (dashboard.isPublic && (
                                    <li
                                        className={
                                            selectedDashboard && selectedDashboard.id === dashboard.id && 'active'
                                        }
                                        onClick={() => updateDashboard(dashboard.id)}
                                    >
                                        <a target="_blank" rel="noopener noreferrer">
                                            {dashboard.title}
                                        </a>
                                    </li>
                                )),
                            )}
                        </ul>
                    </div>
                )
                : <div className="left-menu--loading"><Spinner modifier="spinner--large" /></div>
            }

            <div className="section-title help">
                <div className="section-title__icon">
                    {IconComponents.Help()}
                </div>
                <h1>Help</h1>
            </div>

            <div className="links">
                <ul>
                    <li className="help-list">
                        <a href="http://support.purple.ai" target="_blank" rel="noopener noreferrer">
                            Support guides
                            {IconComponents.ExternalLink()}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
