import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'metroplex';
import AuthenticationService from 'core/framework/services/authentication-service';
import { useRecoilValue } from 'recoil';
import { generic } from 'core/framework/recoil/translations';

const Login = ({ logout }) => {
    const translations = useRecoilValue(generic);

    useEffect(() => {
        window.location.href = AuthenticationService.buildOAuthAccessCodeRequestUrl();
    }, []);

    return (
        <div className="PageWrapper">
            <div className="PageContent PageContent--loading">
                <Spinner
                    page
                    loadingText={logout ? translations.signingOut : translations.sessionExpired}
                />
            </div>
        </div>
    );
};

Login.propTypes = {
    logout: PropTypes.bool,
};

Login.defaultProps = {
    logout: false,
};

export default Login;
