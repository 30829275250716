import { atom } from 'recoil';

const impersonationAtom = atom({
    key: 'impersonationAtom',
    default: {
        userScope: null,
        selectedScope: {
            type: null,
            uniqid: null,
        },
    },
});

export default impersonationAtom;
