import URLSearchParams from '@ungap/url-search-params';

const Browser = {
    is(agent) {
        return navigator.userAgent.toLowerCase().indexOf(agent) > -1;
    },
};

export default Browser;

export function getURLWithoutSearch() {
    return window.location.href.split('?')[0];
}

export function resetURL(url) {
    window.history.pushState({}, null, url);
}

export function getURLParameter(parameter) {
    const urlSearchParams = new URLSearchParams(window.location.search.substring(1));

    return urlSearchParams.get(parameter);
}

export function decodeAndParse(base64) {
    try {
        return JSON.parse(atob(base64));
    } catch (error) {
        return {};
    }
}

export function getURLWithoutData() {
    const urlSearchParams = new URLSearchParams(window.location.search.substring(1));

    urlSearchParams.delete('data');

    if (urlSearchParams.size === 0) {
        return getURLWithoutSearch();
    }

    return `${getURLWithoutSearch()}?${urlSearchParams.toString()}`;
}

export async function getDataFromURL() {
    const hasQueryParams = window.location.href.includes('?');
    const data = hasQueryParams && getURLParameter('data');

    return data ? decodeAndParse(data) : {};
}
