import React from 'react';
import { useRecoilValue } from 'recoil';
import { BlankFeature, Button } from 'metroplex';
import { analytics } from 'core/framework/recoil/translations';
import brandingAtom from 'core/framework/recoil/atoms/branding-atom';
import Confused from 'components/Confused.svg';
import HeaderPattern from 'components/PageHeader/SVGPageHeaderPattern.svg';
import TranslationHelper from 'core/utils/translation-helper';

import 'components/EstateTypeError/EstateTypeError.scss';

const EstateTypeError = () => {
    const { isMainWhitelabel } = useRecoilValue(brandingAtom);
    const translations = useRecoilValue(analytics);
    const confusedIcon = () => <img alt="invalid-scope" src={Confused} />;

    return (
        <div
            className="PageWrapper"
            style={{ backgroundImage: `url(${HeaderPattern})` }}
        >
            <div className="PageContent" id="no-access">
                <BlankFeature
                    title={translations.accessDenied}
                    icon={confusedIcon}
                >
                    <p>
                        {TranslationHelper.interpolateString(
                            translations.accessDeniedInvalidScope,
                            [isMainWhitelabel ? 'purple analytics' : 'Analytics'],
                        )}
                    </p>
                    <div className="return-to-portal">
                        <Button
                            color="primary"
                            size="large"
                            onClick={() => { window.location.href = '/'; }}
                        >
                            {TranslationHelper.interpolateString(
                                translations.backToPortal,
                                [isMainWhitelabel ? 'Purple Portal' : 'Portal'],
                            )}
                        </Button>
                    </div>
                </BlankFeature>
            </div>
        </div>
    );
};

export default EstateTypeError;
