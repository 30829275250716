import { selector } from 'recoil';
import translationsState from 'core/framework/recoil/translations/atom';

const impersonationMenuTranslations = selector({
    key: 'impersonationMenuTranslations',
    get: ({ get }) => {
        const translations = get(translationsState);
        return translations?.portalHeader?.topbar?.impersonationMenu;
    },
});

export default impersonationMenuTranslations;
