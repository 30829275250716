import { selector } from 'recoil';
import page from '../atom';

const showSpinner = selector({
    key: 'showSpinner',
    get: ({ get }) => get(page).showSpinner,
    set: ({ set }, newValue) => set(page, currentState => ({
        ...currentState,
        showSpinner: newValue,
    })),
});

export default showSpinner;
