import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import ColourHelper from 'core/utils/colour-helper';

const TopbarStyling = ({ primaryColour }) => {
    const isPrimaryDark = ColourHelper.isDark(primaryColour);
    const isPrimaryTooDark = ColourHelper.isTooDark(primaryColour);
    const midRange = ColourHelper.isMidRange(primaryColour);
    const darkDefault = Color(primaryColour).darken(midRange ? 0.2 : 0.3).rgb().string();
    const darkHover = Color(primaryColour).darken(midRange ? 0.3 : 0.4).rgb().string();

    const scopeMenuStyling = `
        .Topbar-scope-current {
            background-color: ${darkDefault};
        }

        .Topbar-scope-current:hover {
            background-color: ${darkHover};
        }
    `;

    const buttonStyling = `
        .button--topbar {
            background-color: ${isPrimaryDark ? primaryColour : darkDefault};
        }

        .button--topbar:active {
            box-shadow: none;
            color: #fff;
            background-color: ${isPrimaryDark ? primaryColour : darkHover};
        }

        .button--topbar:hover,
        .button--topbar:focus,
        .dropdown--open .button--topbar {
            background-color: ${isPrimaryDark ? Color(primaryColour).lighten(0.3).rgb().string() : darkHover};
        }
    `;

    const styling = `
        .Topbar .logo .beta {
            background-color: rgba(${isPrimaryDark ? '250, 250, 250' : '0, 0, 0'}, 0.2);
        }

        ${isPrimaryDark && !isPrimaryTooDark ? scopeMenuStyling : ''}

        ${!isPrimaryTooDark ? buttonStyling : ''}
    `;

    return (
        <style>{`.Topbar { background-color: ${primaryColour}; } ${styling}`}</style>
    );
};

TopbarStyling.propTypes = {
    primaryColour: PropTypes.string,
};

TopbarStyling.defaultProps = {
    primaryColour: '#7866ac',
};

export default TopbarStyling;
