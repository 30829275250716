import { selector } from 'recoil';
import translationsState from 'core/framework/recoil/translations/atom';

const analyticsTranslations = selector({
    key: 'analyticsTranslations',
    get: ({ get }) => {
        const translations = get(translationsState);
        return translations?.analytics;
    },
});

export default analyticsTranslations;
