import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import App from 'components/App/App';
import ENV from 'core/utils/env-helper';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <RecoilRoot>
        <BrowserRouter basename={ENV.get().PUBLIC_URL}>
            <App />
        </BrowserRouter>
    </RecoilRoot>,
);
