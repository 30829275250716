import { selector } from 'recoil';
import translationsState from 'core/framework/recoil/translations/atom';

const accountMenuTranslations = selector({
    key: 'accountMenuTranslations',
    get: ({ get }) => {
        const translations = get(translationsState);
        return translations?.portalHeader?.topbar?.accountMenu;
    },
});

export default accountMenuTranslations;
