/* eslint-disable import/no-cycle */
import { Base64 } from 'js-base64';
import pkceChallenge from 'pkce-challenge';
import { isNil } from 'ramda';
import Request from 'core/utils/request';
import StringHelper from 'core/utils/string-helper';
import jwtDecode from 'jwt-decode';
import ENV from 'core/utils/env-helper';

const R = { isNil };

let isRefreshingToken = false;
let refreshQueue = [];

const AuthenticationService = {
    isAuthenticated() {
        return !R.isNil(localStorage.getItem('access_token'));
    },

    getPKCE() {
        return JSON.parse(this.base64Decode(localStorage.getItem('pkce')));
    },

    setPKCE(challenge) {
        localStorage.setItem('pkce', this.base64Encode(JSON.stringify(challenge)));
    },

    getOAuthState() {
        return this.base64Decode(localStorage.getItem('state'));
    },

    setOAuthState(oauthState) {
        localStorage.setItem('state', this.base64Encode(oauthState));
    },

    buildOAuthAccessCodeRequestUrl() {
        const challenge = pkceChallenge();

        this.setPKCE(challenge);

        const oauthState = StringHelper.randomAlphanumericString(32);

        this.setOAuthState(oauthState);

        const authorizeUrl = `${this.getAuthServerUrl('/oauth/authorize')}?`;
        const redirectUri = this.getRedirectUri();

        return [
            authorizeUrl,
            'response_type=code',
            `&client_id=${ENV.get().REACT_APP_AUTH_CLIENT_ID}`,
            `&redirect_uri=${redirectUri}`,
            // `&scope=${ENV.get().REACT_APP_AUTH_SCOPE}`,
            `&state=${oauthState}`,
            `&code_challenge=${challenge.code_challenge}`,
            '&code_challenge_method=S256',
        ].join('');
    },

    getApplicationBaseUrl() {
        const currentHost = window.location.origin;

        return `${currentHost}${ENV.get().PUBLIC_URL}`;
    },

    getApplicationUrl(path) {
        return this.getApplicationBaseUrl() + path;
    },

    getRedirectUri() {
        return this.getApplicationUrl('/oauth');
    },

    getAuthServerBaseUrl() {
        return `${window.location.origin}/services/auth`;
    },

    getAuthServerUrl(path) {
        return this.getAuthServerBaseUrl() + path;
    },

    requestAccessToken(code) {
        const body = {
            client_id: ENV.get().REACT_APP_AUTH_CLIENT_ID,
            code,
            grant_type: 'authorization_code',
            code_verifier: this.getPKCE().code_verifier,
            redirect_uri: this.getRedirectUri(),
        };

        const accessTokenUrl = `${this.getAuthServerBaseUrl()}/oauth/access_token`;

        Request.post(accessTokenUrl, body)
            .then(response => this.onAccessTokenSuccess(response))
            // Disable console warnings that bloat test output
            // eslint-disable-next-line no-console
            .catch(error => console.error(error));
    },

    /**
     * Refresh access token and fire any requests waiting in the queue
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    refreshAccessToken(successCallback, errorCallback) {
        const body = {
            grant_type: 'refresh_token',
            client_id: ENV.get().REACT_APP_AUTH_CLIENT_ID,
            refresh_token: this.getRefreshToken(),
        };

        const refreshTokenUrl = `${this.getAuthServerBaseUrl()}/oauth/access_token`;

        this.setRefreshStatus(true);
        this.queueAfterRefresh(successCallback);

        Request.post(refreshTokenUrl, body)
            .then(response => {
                this.setAccessToken(response.access_token);
                this.setRefreshToken(response.refresh_token);
                refreshQueue.forEach((func, index) => {
                    func(response);
                    if (index === refreshQueue.length - 1) {
                        this.clearRefreshQueue();
                        this.setRefreshStatus(false);
                    }
                });
            })
            .catch(error => errorCallback(error));
    },

    queueAfterRefresh(func) {
        refreshQueue.push(func);
    },

    clearRefreshQueue() {
        refreshQueue = [];
    },

    getRefreshStatus() {
        return isRefreshingToken;
    },

    setRefreshStatus(status) {
        isRefreshingToken = status;
    },

    onAccessTokenSuccess(response) {
        this.setAccessToken(response.access_token);
        this.setRefreshToken(response.refresh_token);

        const { estate: { id: clientId } } = jwtDecode(response.access_token);
        this.setClientId(clientId);

        localStorage.removeItem('pkce');
        localStorage.removeItem('state');

        const returnUrl = localStorage.getItem('returnUrl');
        localStorage.removeItem('returnUrl');
        window.location.href = returnUrl || this.getApplicationBaseUrl();
    },

    setAccessToken(accessToken) {
        localStorage.setItem('access_token', accessToken);
    },

    setRefreshToken(refreshToken) {
        localStorage.setItem('refresh_token', refreshToken);
    },

    setClientId(clientId) {
        localStorage.setItem('clientId', clientId);
    },

    getUserPermissions() {
        const accessToken = this.getAccessToken();
        return accessToken
            ? jwtDecode(accessToken)?.scopes
            : [];
    },

    getEstateType() {
        const accessToken = this.getAccessToken();
        return accessToken
            ? jwtDecode(accessToken)?.estate?.type
            : null;
    },

    getEstateUniqid() {
        const accessToken = this.getAccessToken();
        return accessToken
            ? jwtDecode(accessToken)?.estate?.id
            : null;
    },

    getAccessToken() {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
            return null;
        }

        return accessToken;
    },

    getRefreshToken() {
        const refreshToken = localStorage.getItem('refresh_token');

        if (!refreshToken) {
            return null;
        }

        return refreshToken;
    },

    invalidateAccessToken() {
        localStorage.removeItem('access_token');
        localStorage.setItem('returnUrl', window.location.href);

        window.location.pathname = `${ENV.get().PUBLIC_URL}/login`;
    },

    logout() {
        const returnUrl = this.getApplicationUrl('/logout');
        const logoutUrl = `${this.getAuthServerBaseUrl()}/logout?returnUrl=${returnUrl}`;
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = logoutUrl;
    },

    base64Encode(string) {
        return Base64.encode(string);
    },

    base64Decode(string) {
        return Base64.decode(string);
    },

    getUserGroups(successCallback, errorCallback) {
        Request.get(this.getAuthServerUrl('/api/admin/user-groups'))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getUserDetails(successCallback, errorCallback) {
        Request.get(this.getAuthServerUrl('/api/account/details'))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },
};

export default AuthenticationService;
