/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Spinner } from 'metroplex';
import { BackendProvider, WorkspaceProvider } from '@gooddata/sdk-ui';
import tigerFactory, { TigerJwtAuthProvider } from '@gooddata/sdk-backend-tiger';
import DashboardComponent from 'components/Dashboard/Dashboard';
import AnalyticsService from 'core/framework/services/analytics-service';
import { alert } from 'core/framework/recoil/page';
import Sidebar from 'components/Sidebar/Sidebar';
import PageHeader from 'components/PageHeader/PageHeader';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import workspaceAtom from 'core/framework/recoil/atoms/workspace-atom';

import '@gooddata/sdk-ui-charts/styles/css/main.css';
import '@gooddata/sdk-ui-ext/styles/css/main.css';
import '@gooddata/sdk-ui-filters/styles/css/main.css';
import '@gooddata/sdk-ui-geo/styles/css/main.css';
import '@gooddata/sdk-ui-kit/styles/css/main.css';
import '@gooddata/sdk-ui-pivot/styles/css/main.css';
import '@gooddata/sdk-ui-dashboard/styles/css/main.css';
import 'components/WorkspaceContainer/WorkspaceContainer.scss';

/**
 * WorkspaceContainer component is responsible for housing all the GoodData workspace components
 *
 * @returns {JSX.Element} The rendered WorkspaceContainer component.
 */
const WorkspaceContainer = () => {
    const impersonation = useRecoilValue(impersonationAtom);
    const workspace = useRecoilValue(workspaceAtom);
    const [goodDataBackend, setGoodDataBackend] = useState(null);
    const setAlert = useSetRecoilState(alert);

    useEffect(() => {
        AnalyticsService.getGoodDataAccessToken(
            impersonation.userScope,
            response => {
                const notAuthenticatedHandler = debounce(() => {}, 500);
                const jwtIsAboutToExpire = () => {};
                const secondsBeforeTokenExpirationToCallReminder = 60;

                if (!response.jwt) {
                    return;
                }

                // JWT authentication provider setup
                const jwtAuthProvider = new TigerJwtAuthProvider(
                    response.jwt,
                    notAuthenticatedHandler,
                    jwtIsAboutToExpire,
                    secondsBeforeTokenExpirationToCallReminder,
                );

                // Backend setup with authentication
                const backend = tigerFactory(
                    {
                        hostname: 'https://purple.cloud.gooddata.com',
                    },
                    {
                        packageName: 'my-app',
                        packageVersion: 'my-app-version',
                    },
                ).withAuthentication(jwtAuthProvider);

                setGoodDataBackend(backend);
            },
            error => setAlert({ show: true, message: error.message, type: 'error' }),
        );
    }, [impersonation.userScope]);

    return (
        <div className="workspace-container">
            <PageHeader />
            <Sidebar />
            <div className="dashboard">
                {goodDataBackend
                    ? (
                        <BackendProvider backend={goodDataBackend}>
                            <WorkspaceProvider workspace={workspace.goodDataWorkspaceUuid}>
                                <DashboardComponent />
                            </WorkspaceProvider>
                        </BackendProvider>
                    )
                    : <div className="dashboard--loading"><Spinner modifier="spinner--large" /></div>
                }
            </div>
        </div>
    );
};

export default WorkspaceContainer;
