import { filter, includes } from 'ramda';

const R = { filter, includes };

const requestHandler = (() => {
    let requests = [];

    function findRequest(requestId) {
        return requests.findIndex(request => requestId === request.id);
    }

    function removeRequest(id) {
        requests = requests.filter(request => id !== request.id);
    }

    function abortRequest(requestId) {
        const targetRequest = requests.find(request => requestId === request.id);

        if (targetRequest && targetRequest.abort) {
            targetRequest.abort();
        }
    }

    function abortAll(url = '') {
        const targetRequests = R.filter(request => R.includes(url, request.id), requests);

        targetRequests.forEach(request => {
            request.abort();
            removeRequest(request.id);
        });
    }

    function addRequest(request, allowDuplicateRequests) {
        const requestIndex = findRequest(request.id);

        if (requestIndex >= 0 && !allowDuplicateRequests) {
            requests[requestIndex].abort();
            removeRequest(request.id);
        }

        requests.push(request);
    }

    return {
        addRequest, removeRequest, abortRequest, findRequest, abortAll,
    };
})();

export default requestHandler;
