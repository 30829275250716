import { prop } from 'ramda';
import ReactHtmlParser from 'html-react-parser';
import escapeHTML from 'escape-html';

const R = { prop };
const elements = {
    bold_start: '<b>',
    bold_end: '</b>',
    paragraph_start: '<p>',
    paragraph_end: '</p>',
};

const TranslationHelper = {
    interpolateString(string, params) {
        return ReactHtmlParser(string.replace(/%(.*?)%/g, (placeholder, content) => {
            if (R.prop(content, elements)) {
                return R.prop(content, elements);
            }
            return escapeHTML(params.shift());
        }));
    },
};

export default TranslationHelper;
