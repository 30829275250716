import { selector } from 'recoil';
import translationsState from 'core/framework/recoil/translations/atom';

const placeholderTranslations = selector({
    key: 'placeholderTranslations',
    get: ({ get }) => {
        const translations = get(translationsState);
        return translations?.placeholders;
    },
});

export default placeholderTranslations;
