import ENV from 'core/utils/env-helper';
import Request from 'core/utils/request';

const LevelService = {
    getLevelsApiBaseUrl() {
        const currentHost = window.location.origin;

        return ENV.get().REACT_APP_LEVELS_API_URL || `${currentHost}/services/levels`;
    },

    getLevelsApiUrl(path) {
        return `${this.getLevelsApiBaseUrl()}${path}`;
    },

    getUserScope(successCallback, errorCallback) {
        Request.get(this.getLevelsApiUrl('/v1/entities/user/level-type'))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getImpersonationHierarchy({ type, uniqid }, successCallback, errorCallback) {
        Request.get(this.getLevelsApiUrl(`/v1/entities/${type}/${uniqid}/descendants`))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getVenueIds(uniqids, successCallback, errorCallback) {
        const encodedUniqids = btoa(JSON.stringify(uniqids));

        Request.get(this.getLevelsApiUrl(`/v1/entities/venue/ids?uniqids=${encodedUniqids}`))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },
};

export default LevelService;
