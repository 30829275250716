import React, {
    useEffect,
    useState,
    Fragment,
    useMemo,
} from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
    Dropdown,
    Button,
    Icon,
    Spinner,
    Tree,
} from 'metroplex';
import { impersonationMenu } from 'core/framework/recoil/translations';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import { alert } from 'core/framework/recoil/page';
import LevelService from 'core/framework/services/level-service';
import icons from 'core/icons';
import hierarchyAtom from 'core/framework/recoil/atoms/hierarchy-atom';

import './ImpersonationMenu.scss';

/**
 * The ImpersonationMenu component renders a dropdown impersonation menu for a user to switch to different levels.
 *
 * @returns {JSX.Element} The rendered ImpersonationMenu component.
 */
const ImpersonationMenu = () => {
    const translations = useRecoilValue(impersonationMenu);
    const [impersonation, setImpersonation] = useRecoilState(impersonationAtom);
    const [hierarchy, setHierarchy] = useRecoilState(hierarchyAtom);
    const [loading, setLoading] = useState(false);
    const setAlert = useSetRecoilState(alert);

    const impersonationScopeUniqid = useMemo(() => (
        impersonation.selectedScope?.uniqid
        || impersonation.userScope?.uniqid
    ), [impersonation]);

    const impersonationScopeLabel = useMemo(() => {
        const findLabel = items => {
            let label = null;

            items.forEach(item => {
                if (item.uniqid === impersonationScopeUniqid) {
                    label = item.label;
                } else if (item.children) {
                    const childLabel = findLabel(item.children);

                    if (childLabel) {
                        label = childLabel;
                    }
                }
            });

            return label;
        };

        return findLabel([hierarchy]);
    }, [impersonation, hierarchy]);

    useEffect(() => {
        setLoading(true);

        LevelService.getImpersonationHierarchy(
            impersonation.userScope,
            response => {
                setHierarchy(response);
                setLoading(false);
            },
            error => {
                setAlert({ show: true, message: error.message, type: 'error' });
            },
        );
    }, [impersonation.userScope]);

    /**
     * Changes the impersonation level based on the provided unique identifier.
     * Determines the type of impersonation (venue, company, group) from the unique identifier prefix.
     * Updates the impersonation state with the new scope type and unique identifier.
     *
     * @param {string} scopeType - The type of the impersonation level.
     * @param {string} scopeUniqid - The unique identifier for the impersonation level.
     */
    const changeImpersonationLevel = ({ type, uniqid }) => {
        if (!uniqid || uniqid === impersonationScopeUniqid) {
            return;
        }

        if (type) {
            setImpersonation(prevState => ({
                ...prevState,
                selectedScope: { type, uniqid },
            }));
        }
    };

    /**
     * The hierarchy of open nodes based on the current impersonation scope.
     * This function identifies the parent nodes of the current impersonation scope and updates the state
     * to reflect the open hierarchy, ensuring the correct nodes are expanded in the UI.
     */
    const openHierarchy = useMemo(() => {
        const openNode = impersonationScopeUniqid;
        const parentIds = [openNode];

        /**
         * Recursively finds and collects the parent IDs of a target node within a hierarchy.
         *
         * @param {Array} nodes - The array of nodes to search through.
         * @param {string} targetId - The unique identifier of the target node.
         * @returns {boolean} - Returns true if the target node is found, otherwise false.
         */
        const findParentIds = (nodes, targetId) => {
            let found = false;
            nodes.forEach(node => {
                if (node.uniqid === targetId) {
                    found = true;
                } else if (node.children && findParentIds(node.children, targetId)) {
                    parentIds.unshift(node.uniqid);
                    found = true;
                }
            });

            return found;
        };

        findParentIds([hierarchy], openNode);
        return parentIds;
    }, [impersonation.selectedScope, hierarchy]);

    const getHierarchyIcon = (node, hasChildren, isOpen) => {
        if (hasChildren) {
            if (node.type === 'company') {
                return <Icon icon={icons.scopeCustomer} />;
            }

            return isOpen
                ? <Icon classNames="open" icon={icons.scopeGroupOpen} />
                : <Icon classNames="closed" icon={icons.scopeGroup} />;
        }

        return <Icon icon={icons.scopeVenue} />;
    };

    return (
        <Dropdown
            dropRight
            size="normal"
            classNames="AccountMenu Dropdown--impersonation-menu"
            button={(
                <Button topbar>
                    {impersonationScopeLabel}
                    <span className="caret"><Icon icon={icons.dropdownCaret} /></span>
                </Button>
            )}
            render={closeDropdown => (
                !loading ? (
                    <Fragment>
                        <span>{translations.setScope}</span>
                        <div className="ImpersonationList">
                            <Tree
                                nodes={[hierarchy]}
                                openNodes={openHierarchy}
                                idAttribute="uniqid"
                                renderNode={(node, hasChildren, toggleMenu, isOpen, classes) => (
                                    <div id={node.uniqid} className={`${classes} ${impersonationScopeUniqid === node?.uniqid ? 'ImpersonationScope' : ''} EstateItem`}>
                                        <div className="EstateLabel" onClick={node.type !== 'company' ? toggleMenu : undefined}>
                                            {getHierarchyIcon(node, hasChildren, isOpen)}
                                            <p>{node.label}</p>
                                        </div>
                                        <Button
                                            className="ImpersonationButton"
                                            onClick={() => {
                                                changeImpersonationLevel(node);
                                                closeDropdown();
                                            }}
                                        >
                                            <Icon icon={icons.impersonationEye} />
                                        </Button>
                                    </div>
                                )}
                            />
                        </div>
                    </Fragment>
                ) : <div className="AccountMenu__dropdown--loading"><Spinner modifier="spinner--large" /></div>
            )}
        />
    );
};

export default ImpersonationMenu;
