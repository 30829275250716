/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { values, mergeLeft } from 'ramda';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    changeAttributeFilterSelection,
    selectFilterContextAttributeFilterByDisplayForm,
    useDashboardSelector,
    useDispatchDashboardCommand,
    resetDashboard,
} from '@gooddata/sdk-ui-dashboard';
import { attributeDisplayFormRef, newAttribute } from '@gooddata/sdk-model';
import hierarchyAtom from 'core/framework/recoil/atoms/hierarchy-atom';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import levelService from 'core/framework/services/level-service';
import { alert } from 'core/framework/recoil/page';

const R = { values, mergeLeft };

/**
 * Component to render 'VenueFilterWidget'. If you create custom widget instance and also pass extra data,
 * then that data will be available in the `widget` prop.
 *
 * @returns {null}
 */
const VenueFilterWidget = () => {
    const [filterUpdated, setFilterUpdated] = useState(false);
    const impersonation = useRecoilValue(impersonationAtom);
    const hierarchy = useRecoilValue(hierarchyAtom);
    const setAlert = useSetRecoilState(alert);
    const venueFilter = newAttribute('wifi_gooddata_venue.id');

    useEffect(() => {
        setFilterUpdated(false);
    }, [impersonation]);

    const getVenues = (nodes, expectedUniqid, venueIds = [], estateFound = false) => {
        nodes.forEach(node => {
            if ((estateFound || node.uniqid === expectedUniqid) && node.type === 'venue') {
                venueIds.push(node.uniqid);
            }

            if (node.children?.length > 0) {
                getVenues(
                    node.children,
                    expectedUniqid,
                    venueIds,
                    estateFound || node.uniqid === expectedUniqid,
                );
            }
        });

        return venueIds;
    };

    // Select the attribute filter's local identifier from the filter's display form.
    const venueFilterSelector = useDashboardSelector(
        selectFilterContextAttributeFilterByDisplayForm(attributeDisplayFormRef(venueFilter)),
    )?.attributeFilter.localIdentifier;

    const changeAttributeFilterSelectionCmd = useDispatchDashboardCommand(changeAttributeFilterSelection);
    const resetDashboardCmd = useDispatchDashboardCommand(resetDashboard);

    if (!venueFilterSelector || filterUpdated) {
        return null;
    }

    const { type, uniqid } = impersonation.selectedScope.uniqid === null
        ? impersonation.userScope
        : impersonation.selectedScope;

    if (type === 'company') {
        resetDashboardCmd();
        setFilterUpdated(true);

        return null;
    }

    const venueIds = getVenues([hierarchy], uniqid);

    levelService.getVenueIds(
        venueIds,
        response => {
            const ids = R.values(response);

            // Dispatching the command WITH impersonation values
            changeAttributeFilterSelectionCmd(
                venueFilterSelector,
                {
                    values: ids.map(String),
                },
                'IN',
            );

            setFilterUpdated(true);
        },
        error => setAlert({ show: true, message: error.message, type: 'error' }),
    );

    return null;
};

export default VenueFilterWidget;
