import { atom } from 'recoil';

const page = atom({
    key: 'page',
    default: {
        showSpinner: false,
        alert: {
            show: false,
            message: '',
            type: 'default',
        },
    },
});

export default page;
