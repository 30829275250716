import { selector } from 'recoil';
import page from '../atom';

const alert = selector({
    key: 'setAlert',
    get: ({ get }) => get(page).alert,
    set: ({ set }, newValue) => {
        set(page, currentState => ({
            ...currentState,
            alert: newValue,
        }));
    },
});

export default alert;
