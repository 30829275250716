import React from 'react';
import { useRecoilValue } from 'recoil';
import spinner from 'components/WorkspaceLoading/spinner.gif';
import { analytics } from 'core/framework/recoil/translations';
import 'iframe-resizer/js/iframeResizer.contentWindow.min';
import 'components/WorkspaceLoading/WorkspaceLoading.scss';

const LoadingSpinner = () => {
    const translations = useRecoilValue(analytics);

    return (
        <div className="WorkspaceLoadingSpinner">
            <img src={spinner} alt="" />
            <h2>{translations.workspaceLoading.heading}</h2>
            <br />
            <p>{translations.workspaceLoading.body}</p>
        </div>
    );
};

const WorkspaceLoading = () => (
    <div id="WorkspaceLoading" data-iframe-height>
        <div className="WorkspaceLoadingPattern WorkspaceLoadingPattern--top" />
        <div className="WorkspaceLoadingPattern WorkspaceLoadingPattern--bottom" />
        <LoadingSpinner />
    </div>
);

export default WorkspaceLoading;
