/* eslint-disable import/no-cycle */
import xhr from 'xhr';
import RequestHandler from 'core/utils/request-handler';
import AuthenticationService from '../framework/services/authentication-service';

const generateAPIUrl = url => `${process.env.REACT_APP_PORTAL_URL}${url}`;

const getClientId = () => localStorage.getItem('clientId');

const getAuthorizationHeader = () => `Bearer ${localStorage.getItem('access_token') || '%access_token%'}`;

const Request = {
    get(endpointUrl, body = {}, method = 'GET', allowDuplicateRequests, headers = {}) {
        const retryFn = () => Request.get(endpointUrl, body, method, allowDuplicateRequests, headers);

        return this.query({
            withCredentials: true,
            url: endpointUrl,
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': getAuthorizationHeader(),
                ...headers,
            },
            body: JSON.stringify(body),
        }, allowDuplicateRequests, retryFn);
    },

    post(endpointUrl, body = {}, method = 'POST', allowDuplicateRequests, headers = {}) {
        const hasFormData = body instanceof FormData;
        const retryFn = () => Request.post(endpointUrl, body, method, allowDuplicateRequests, headers);

        return this.query({
            withCredentials: true,
            url: endpointUrl,
            method,
            headers: hasFormData ? null : {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': getAuthorizationHeader(),
                ...headers,
            },
            body: hasFormData ? body : JSON.stringify(body),
        }, allowDuplicateRequests, retryFn);
    },

    put(endpointUrl, body = {}, method = 'PUT', allowDuplicateRequests, headers = {}) {
        const retryFn = () => Request.put(endpointUrl, body, method, allowDuplicateRequests, headers);

        return this.query({
            withCredentials: true,
            url: endpointUrl,
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify(body),
        }, allowDuplicateRequests, retryFn);
    },

    patch(endpointUrl, body = {}, method = 'PATCH', allowDuplicateRequests, headers = {}) {
        const retryFn = () => Request.patch(endpointUrl, body, method, allowDuplicateRequests, headers);

        return this.query({
            withCredentials: true,
            url: endpointUrl,
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify(body),
        }, allowDuplicateRequests, retryFn);
    },

    upload(endpointUrl, formData) {
        const retryFn = () => Request.upload(endpointUrl, formData);

        return this.query({
            withCredentials: true,
            url: endpointUrl,
            method: 'POST',
            body: formData,
        }, false, retryFn);
    },

    delete(endpointUrl, body = {}, method = 'DELETE', allowDuplicateRequests, headers) {
        const retryFn = () => Request.delete(endpointUrl, body, method, allowDuplicateRequests, headers);

        return this.query({
            withCredentials: true,
            url: endpointUrl,
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': getAuthorizationHeader(),
                ...headers,
            },
            body: JSON.stringify(body),
        }, allowDuplicateRequests, retryFn);
    },

    query(request, allowDuplicateRequests = false, retryFn) {
        return new Promise((resolve, reject) => {
            const currentRequest = xhr(request, (error, response, body) => {
                try {
                    if (!allowDuplicateRequests) {
                        RequestHandler.removeRequest(request.url);
                    }

                    if (response.statusCode === 401 && window.location.pathname !== '/login') {
                        // Use refresh token if we have it
                        if (AuthenticationService.getRefreshToken() && !request.url.includes('access_token')) {
                            const isRefreshingToken = AuthenticationService.getRefreshStatus();

                            // Only try to refresh the token once
                            if (!isRefreshingToken) {
                                const refreshRequest = AuthenticationService.refreshAccessToken(
                                    // Call this function after refresh, retries the original request, gets added to a queue
                                    () => retryFn()
                                        .then(retryResponse => {
                                            resolve(retryResponse);
                                        })
                                        .catch(retryError => {
                                            reject(retryError);
                                        }),
                                    // Call this function if any error occurs, falls back to login process
                                    () => {
                                        reject(new Error('Oh no, something went wrong!'));
                                        AuthenticationService.invalidateAccessToken();
                                    },
                                );

                                return refreshRequest;
                            }

                            // If already refreshing, add retry function to queue
                            return AuthenticationService.queueAfterRefresh(
                                () => retryFn()
                                    .then(retryResponse => resolve(retryResponse))
                                    .catch(retryError => reject(retryError)),
                            );
                        }

                        // Fall back to login process
                        return AuthenticationService.invalidateAccessToken();
                    }

                    if (response.statusCode === 204) {
                        return resolve();
                    }

                    if (response.statusCode === 404) {
                        // @todo - This should only happen if the 404 is integral to the page, e.g. the location
                        // I had this happen to me when requesting something minor and it shouldn't completely redirect the user
                        // window.location = '/page-not-found';

                        // Automatically resolve any DELETE request that receives a 404 response
                        // While a valid response we don't want it to be shown to the user
                        if (request.method === 'DELETE') {
                            resolve();
                        }
                    }

                    if (response.statusCode === 500) {
                        return reject(new Error('Oh no, something went wrong!'));
                    }

                    if (error || !(response.statusCode >= 200 && response.statusCode <= 299) || !body) {
                        return body
                            ? reject(JSON.parse(body))
                            : reject(new Error('Oh no, something went wrong!'));
                    }

                    if (JSON.parse(body).promptLogin) {
                        window.location = '/login';
                    }

                    return resolve(JSON.parse(body));
                } catch (err) {
                    return reject(new Error('Oh no, something went wrong!'));
                }
            });

            RequestHandler.addRequest({
                id: request.url.split('?')[0],
                abort: () => {
                    currentRequest.abort();
                },
            }, allowDuplicateRequests);
        });
    },
};

export {
    generateAPIUrl,
    getClientId,
};

export default Request;
