import AuthenticationService from 'core/framework/services/authentication-service';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CrossDomainIFrame = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        window.parent.postMessage('access-token-request', '*');

        window.addEventListener('message', ({ data }) => {
            if (data.type === undefined) {
                return;
            }

            if (data.type === 'set-access-token') {
                const accessToken = data.value;
                AuthenticationService.setAccessToken(accessToken);
                setAuthenticated(true);
            }
        });
    }, []);

    return (
        <div className="CrossDomainIFrame">
            {isAuthenticated && children}
        </div>
    );
};

CrossDomainIFrame.propTypes = {
    children: PropTypes.node,
};

CrossDomainIFrame.defaultProps = {
    children: null,
};

export default CrossDomainIFrame;
