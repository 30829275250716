import ENV from 'core/utils/env-helper';
import Request from 'core/utils/request';

const AnalyticsService = {
    getAnalyticsApiBaseUrl() {
        const currentHost = window.location.origin;

        return ENV.get().REACT_APP_ANALYTICS_API_URL || `${currentHost}/services/analytics`;
    },

    getAnalyticsApiUrl(path) {
        return `${this.getAnalyticsApiBaseUrl()}${path}`;
    },

    getWorkspace({ type, uniqid }, successCallback, errorCallback) {
        Request.get(this.getAnalyticsApiUrl(`/v1/entities/workspaces/${type}/${uniqid}`))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getDashboards({ type, uniqid }, successCallback, errorCallback) {
        Request.get(this.getAnalyticsApiUrl(`/v1/entities/dashboards/${type}/${uniqid}`))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getGoodDataAccessToken({ type, uniqid }, successCallback, errorCallback) {
        Request.get(this.getAnalyticsApiUrl(`/v1/generate-jwt/${type}/${uniqid}`))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },
};

export default AnalyticsService;
